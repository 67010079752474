import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.main/view.scss */
p.blue {
  color: blue;
}`],
})
export class PageMainComponent implements OnInit {
    public randomNumbers: number[] = []; // 항상 표시될 랜덤 번호 배열
    public recentNumbers: any[] = []; // 최근 당첨번호 저장
    public loading: boolean = false; // 로딩 상태 추가

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        // await this.loadRandomNumbers(); // API에서 랜덤 번호 가져오기
        await this.loadRecentNumbers(); // 최근 당첨 번호 로드
    }

    // API를 통해 랜덤 번호 가져오기
    public async loadRandomNumbers() {
        this.loading = true; // 로딩 시작
        this.randomNumbers = [];
        try {
            const { code, data } = await wiz.call("generate_random_numbers");
            if (code === 200 && Array.isArray(data)) {
                this.randomNumbers = data.sort((a, b) => a - b); // 랜덤 번호를 정렬
            } else {
                console.error("Failed to fetch random numbers or invalid response format.");
            }
        } catch (error) {
            console.error("An error occurred while fetching random numbers:", error);
        } finally {
            this.loading = false; // 로딩 종료
            await this.service.render();
        }
    }

    // 최근 당첨 번호 로드
    public async loadRecentNumbers() {
        const { code, data } = await wiz.call("get_recent_numbers");
        if (code === 200 && Array.isArray(data)) {
            this.recentNumbers = data
                .sort((a, b) => b.round_number - a.round_number) // round_number 내림차순 정렬
                .slice(0, 5) // 상위 5개 가져오기
                .map(round => ({
                    round_number: round.round_number,
                    first_place: round.first_place, // 당첨자 수
                    first_prize: round.first_prize, // 당첨 금액
                    created: this.formatDate(round.created), // 날짜 추가
                    numbers: [
                        round.first,
                        round.second,
                        round.third,
                        round.fourth,
                        round.fifth,
                        round.sixth
                    ].sort((a, b) => a - b) // 각 라운드의 번호도 오름차순 정렬
                }));
        } else {
            console.error("Failed to fetch recent numbers or invalid response format.");
        }
        await this.service.render();
    }

    // 날짜를 "YYYY-MM-DD" 형식으로 변환하는 메서드
    private formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // 번호 색상 클래스 할당
    getNumberClass(number: number): string {
        if (number >= 1 && number <= 9) {
            return "bg-red-400 text-white"; // 1~9
        } else if (number >= 10 && number <= 19) {
            return "bg-orange-400 text-white"; // 10~19
        } else if (number >= 20 && number <= 29) {
            return "bg-yellow-400 text-white"; // 20~29
        } else if (number >= 30 && number <= 39) {
            return "bg-green-400 text-white"; // 30~39
        } else if (number >= 40 && number <= 45) {
            return "bg-blue-400 text-white"; // 40~45
        }
        return "bg-gray-400 text-white"; // 기본 색상
    }

}

export default PageMainComponent;