import '@angular/compiler';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageMainComponent } from './page.main/page.main.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PageAddComponent } from './page.add/page.add.component';
import { PageHistoryComponent } from './page.history/page.history.component';
import { ComponentCardComponent } from './component.card/component.card.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageXyzComponent } from './page.xyz/page.xyz.component';
import { PageResultComponent } from './page.result/page.result.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { LayoutNoneComponent } from './layout.none/layout.none.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageRoundComponent } from './page.round/page.round.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { HighlightDirective } from "src/libs/directives/highlight.directive";

const INDEX_PAGE = "main";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutNavbarComponent,
        "children": [
            {
                "path": "main/:id?",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "add",
                component: PageAddComponent,
                "app_id": "page.add"
            },
            {
                "path": "history",
                component: PageHistoryComponent,
                "app_id": "page.history"
            },
            {
                "path": "xyz/:page",
                component: PageXyzComponent,
                "app_id": "page.xyz"
            },
            {
                "path": "result",
                component: PageResultComponent,
                "app_id": "page.result"
            },
            {
                "path": "round",
                component: PageRoundComponent,
                "app_id": "page.round"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }