import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.history');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-history',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.history/view.scss */
/* src/app/page.history/view.scss: no such file or directory */`],
})
export class PageHistoryComponent implements OnInit {
    public historyRecords: any[] = []; // 히스토리 데이터를 저장할 배열
    public loading: boolean = false;  // 로딩 상태 추가

    constructor(@Inject( Service) public service: Service) {}

    public async ngOnInit() {
        await this.service.init();
        await this.loadHistory(); // 히스토리 데이터 로드
    }

    // 히스토리 데이터 API 호출
    public async loadHistory() {
        this.loading = true; // 로딩 시작
        try {
            const { code, data } = await wiz.call("get_history");
            if (code === 200 && Array.isArray(data)) {
                this.historyRecords = data; // 히스토리 데이터 저장
            } else {
                console.error("Failed to fetch history data or invalid response format.");
            }
        } catch (error) {
            console.error("An error occurred while fetching history data:", error);
        } finally {
            this.loading = false; // 로딩 종료
            await this.service.render();
        }
    }
}

export default PageHistoryComponent;