import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.result');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-result',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.result/view.scss */
/* src/app/page.result/view.scss: no such file or directory */`],
})
export class PageResultComponent implements OnInit {
    public results: any = { details: [], rank_counts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } }; // 기본값 설정
    public targetRound: number = 0; // 타겟 라운드 번호 추가
    public winningNumbers: any = {}; // 당첨 번호 저장

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadLastWeekResults(); // 지난 주 생성 결과 로드
    }

    // 지난 주 생성 결과 로드
    public async loadLastWeekResults() {
        try {
            const { code, data } = await wiz.call("get_last_week_results");
            if (code === 200 && data) {
                this.targetRound = data.target_round || 0; // 타겟 라운드 번호 설정
                this.winningNumbers = data.winning_numbers || {}; // 당첨 번호 설정
                this.results = data || { details: [], rank_counts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } }; // 결과 설정
            } else {
                console.error("Failed to fetch last week results or invalid response format.");
                this.targetRound = 0;
                this.winningNumbers = {};
                this.results = { details: [], rank_counts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } };
            }
        } catch (error) {
            console.error("An error occurred while fetching last week results:", error);
            this.targetRound = 0;
            this.winningNumbers = {};
            this.results = { details: [], rank_counts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } };
        } finally {
            await this.service.render();
        }
    }

    // 번호가 당첨 번호와 일치하는지 확인
    public isNumberMatched(number: number, winningNumbers: any): boolean {
        const { first, second, third, fourth, fifth, sixth } = winningNumbers;
        return [first, second, third, fourth, fifth, sixth].includes(number);
    }

    // 등수 텍스트 변환
    public getRankText(rank: number): string {
        switch (rank) {
            case 1: return "1등";
            case 2: return "2등";
            case 3: return "3등";
            case 4: return "4등";
            case 5: return "5등";
            default: return "낙첨";
        }
    }
}

export default PageResultComponent;