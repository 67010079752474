import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.add');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-add',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.add/view.scss */
/* src/app/page.add/view.scss: no such file or directory */`],
})
export class PageAddComponent implements OnInit {
    public latestCreated: string | null = null;

    constructor(@Inject( Service) public service: Service) {}

    async ngOnInit() {
        await this.service.init();
        await this.fetchLatestCreated();
        await this.service.render();
    }

    public async fetchLatestCreated() {
        try {
            this.service.loading.show();
            const { code, data } = await wiz.call("get_latest_created");
            this.service.loading.hide();

            if (code === 200 && data?.created) {
                this.latestCreated = data.created;
                console.log("Fetched Latest Created Date:", this.latestCreated);
            } else {
                console.error("Failed to fetch latest created date:", data?.message || "Invalid response");
            }
        } catch (error) {
            this.service.loading.hide();
            console.error("Error fetching latest created date:", error);
        }
    }
}

export default PageAddComponent;